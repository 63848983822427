
.bead-container {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%; /* Adjust as needed */
    height: 100px; /* Adjust as needed */
    overflow: hidden; /* Ensure beads are hidden when outside the container */
}


.bead-container::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 2px; /* Adjust as needed */
  background-color: black; /* Line color */
  top: 50%;
  left: 0;
  transform: translateY(-50%);
}
.bead {
  background-color: white;
  width: 24px; /* Adjust the size as needed */
  height: 24px;
  border-radius: 9999px; /* Full rounded corners */
  opacity: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 2px;
  z-index: 10;
  text-shadow: 0.8px 0.8px 0.8px rgb(182, 182, 182);
  box-shadow: 0.8px 0.8px rgb(182, 182, 182);  
}
.bead.center {
  transform: translateX(0); /* Initially centered */
  animation: string-beads-center 3s linear;
  animation-fill-mode: forwards;
  animation-iteration-count: 1; /* This makes the animation run only once */

}
.bead.start {
  animation: string-beads 8s linear infinite;
  animation-fill-mode: forwards;
}
  
  
@keyframes string-beads {
  0% {
    transform: translateX(-2000%);
  }
  20% {
    transform: translateX(50%); /* Pause at 50% */
  }
  50% {
    transform: translateX(50%); /* Pause at 50% */
  }
  60% {
    transform: translateX(50%); /* Pause at 50% */
  }
  100% {
    transform: translateX(2000%); /* Move off-screen to the right */
  }
}


/* Vendor prefixes for better compatibility */
@-webkit-keyframes string-beads {
  0% {
    -webkit-transform: translateX(-2000%);
  }
  40% {
    -webkit-transform: translateX(50%);
  }
  50% {
    -webkit-transform: translateX(50%);
  }
  60% {
    -webkit-transform: translateX(50%);
  }
  100% {
    -webkit-transform: translateX(2000%);
  }
}

@-moz-keyframes string-beads {
  0% {
    -moz-transform: translateX(-2000%);
  }
  40% {
    -moz-transform: translateX(50%);
  }
  50% {
    -moz-transform: translateX(50%);
  }
  60% {
    -moz-transform: translateX(50%);
  }
  100% {
    -moz-transform: translateX(2000%);
  }
}

@keyframes string-beads-center {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(6000%); /* Move off-screen to the right */
  }
}
  
.text-black {
  color: black;
}
  
.text-red {
  color: red;
}